<template>
  <div class="login-form">
    <q-card class="q-pa-lg">
      <q-card-section>
        <div class="text-center">
          <AppLogoOnly />
          <div class="text-h6 q-mt-md">{{ $t('login') }}</div>
          <div class="text-subtitle2">{{ $t('login_into_dashboard') }}</div>
        </div>
      </q-card-section>

      <q-form ref="formRef">
        <q-input
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || requiredRule.email]"
          outlined
          v-model="form.email"
          dense
          autocomplete="email"
          :placeholder="$t('label.register.input_your_email')"
        >
          <template v-slot:prepend>
            <q-icon name="email" />
          </template>
        </q-input>

        <q-input
          type="password"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || requiredRule.password]"
          outlined
          v-model="form.password"
          dense
          autocomplete="password"
          :placeholder="$t('label.register.input_your_password')"
        >
          <template v-slot:prepend>
            <q-icon name="vpn_key" />
          </template>
        </q-input>
        <div class="row no-wrap items-center">
          <q-btn class="btn-save" @click="onSubmit"> {{ $t('login_now') }} </q-btn>
          <q-space />
          <q-btn flat color="primary" @click="onGotoRegister">{{ $t('new_account') }} </q-btn>
        </div>

        <q-separator class="q-my-md" />
      </q-form>
    </q-card>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ACTION_PROFILE } from '@/store/actions'
import AppLogoOnly from '@/components/common/AppLogoOnly.vue'
import LoginRegisterMixin from './mixins/LoginRegisterMixin.vue'
import errorHandler from '@/utils/errorHandler'
import { localStore } from '@/utils/localstore'

@Options({
  components: { AppLogoOnly },
  directives: { maska },
})
export default class LoginForm extends mixins(LoginRegisterMixin) {
  form = {
    email: '',
    password: '',
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get requiredRule() {
    const requiredRule = {
      email: this.$t('validate.please_input_your_valid_email'),
      password: this.$t('validate.please_input_your_valid_password'),
    }
    return requiredRule
  }

  async onSubmit() {
    this.$refs.formRef
      .validate()
      .then((success: boolean) => {
        if (!success) {
          return
        }

        this.doLogin()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doLogin() {
    const { email, password } = this.form
    const { dispatch } = this.$store

    if (email && password) {
      // localStore.removeLocalData()
      await dispatch(ACTION_PROFILE.LOGIN, {
        email,
        password,
      }).catch((error: Error) => {
        errorHandler(error)
      })
    }
  }

  redirectAfterLogin() {
    this.goto('home')
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  onGotoRegister() {
    this.$router.push({ name: 'register' })
  }
}
</script>
<style lang="scss" scoped>
.login-form {
  max-width: 500px;
  margin: auto;
  margin-top: 100px;
}
</style>
